<template>
  <b-card>
    <g-form @submit="save">
      <b-row>
        <b-col md="4">
          <b-form-group>
            <g-field
              :value.sync="selectedItem.tenantId"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="tenants"
              field="select"
              name="dealer"
              rules="required"
              :options="tenants"
              label="domainName"
              @input="() => {}"
              @change="(v) => {
                getTenantCompany(v)
              }"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <!-- tenantYears  -->
          <b-form-group>
            <g-field
              :value.sync="fiscalYear"
              :dir="isRight ? 'rtl' : 'ltr'"
              label-text="fiscalYear"
              field="select"
              name="fiscalYear"
              :options="tenantData.fiscalYears"
              label="year"
              @input="() => {}"
              @change="(v) => {
                setYearData(v);
              }"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <!-- year  -->
          <g-field
            id="year"
            :disabled="fiscalYear != null"
            :value.sync="selectedItem.year"
            label-text="NewYear"
            :rules="fiscalYear ? '' : 'required|yearDigit'"
            name="year"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col md="4">
          <g-picker
            :value.sync="selectedItem.startDate"
            label-text="startDate"
          />
        </b-col>
        <b-col md="4">
          <g-picker
            :value.sync="selectedItem.endDate"
            label-text="endDate"
          />
        </b-col>
        <!-- <b-col md="4">
          <b-form-group class="d-inline" :label="$t('edit')">
            <b-form-checkbox
              v-model="selectedItem.isActive"
              class="mr-0 mt-50"
              name="is-rtl"
              inline
            />
          </b-form-group>
        </b-col> -->
      </b-row>
      <b-row>
        <b-col cols="12">
          <hr />
        </b-col>
        <b-col cols="12" class="d-flex justify-content-end">
          <b-button
            class="mx-1"
            type="submit"
            variant="primary"
            data-action-type="save"
          >
            {{ $t("save") }}
          </b-button>
        </b-col>
      </b-row>
    </g-form>
  </b-card>
</template>
<script>
export default {
  props: ['id'],
  data() {
    return {
      selectedItem: {},
      profile: JSON.parse(window.localStorage.getItem('@USER_PROFILE')),
      tenants: [],
      tenantData: {},
      fiscalYear: null
    };
  },
  watch: {
    'selectedItem.year'(newVal) {
      if (newVal) {
        if (new RegExp(/^(19|20)\d{2}$/).test(newVal)) {
            this.selectedItem.startDate = this.getDate(new Date(newVal, 0, 1));
            this.selectedItem.endDate = this.getDate(new Date(newVal, 11, 1));
        }
        this.fiscalYear = null;
      }
    },
  },
  computed: {},
  beforeMount() {
    this.getTeanets();
  },
  mounted() { },
  methods: {
    setYearData(year) {
        this.selectedItem.startDate = this.getDate(year.startDate);
        this.selectedItem.endDate = this.getDate(year.endDate);
        this.selectedItem.year = null;
    },
    getTeanets() {
      this.get({ url: 'Tenants' }).then((data) => {
        this.tenants = data;
      });
    },
    getTenantCompany(item) {
      this.get({ url: `Tenants/${item.id}/company` }).then((data) => {
        this.tenantData = data;
        this.tenantData.fiscalYears.forEach(element => {
            element.id = element.year
        });
      });
    },
    save() {
      if (this.fiscalYear) {
        const fixData = {
            ...this.selectedItem,
            year: this.fiscalYear
        }
        this.update({
          url: 'FiscalYears',
          data: fixData,
        }).then(() => {
          this.doneAlert({ text: this.$t('updatedSuccessfully') });
        //   fixData = {};
          this.selectedItem = {};
        //   this.$router.push({ name: 'admin-home' });
        });
      } else {
        this.selectedItem.createdBy = this.profile.userId;
        this.create({
          url: 'FiscalYears',
          data: this.selectedItem,
        }).then(() => {
          this.doneAlert({
            text: this.$t('savedSuccessfully'),
          });
          this.selectedItem = {};
        //   this.$router.push({ name: 'admin-home' });
        });
      }
    },
  },
};
</script>
